export const AUTH_START = 'AUTH_START';
export const AUTH_CUSER_START = 'AUTH_CUSER_START';
export const PROFILE_START = 'PROFILE_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_CUSER_SUCCESS = 'AUTH_CUSER_SUCCESS';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CUSER_FAIL = 'AUTH_CUSER_FAIL';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FECTH_STATS_START = 'FECTH_STATS_START';
export const FECTH_STATS_SUCCESS = 'FECTH_STATS_SUCCESS';
export const FECTH_STATS_FAIL = 'FECTH_STATS_FAIL';
export const FECTH_STATS_SPECIES_START = 'FECTH_STATS_SPECIES_START';
export const FECTH_STATS_SPECIES_SUCCESS = 'FECTH_STATS_SPECIES_SUCCESS';
export const FECTH_STATS_SPECIES_FAIL = 'FECTH_STATS_SPECIES_FAIL';

export const FECTH_SPECIES_START = 'FECTH_SPECIES_START';
export const FECTH_SPECIES_SUCCESS = 'FECTH_SPECIES_SUCCESS';
export const FECTH_SPECIES_FAIL = 'FECTH_SPECIES_FAIL';
export const FECTH_SPECIES_ANNOT_START = 'FECTH_SPECIES_ANNOT_START';
export const FECTH_SPECIES_ANNOT_SUCCESS = 'FECTH_SPECIES_ANNOT_SUCCESS';
export const FECTH_SPECIES_ANNOT_FAIL = 'FECTH_SPECIES_ANNOT_FAIL';

export const FETCH_SCHEMA_ALLELE_MODE_START = 'FETCH_SCHEMA_ALLELE_MODE_START';
export const FETCH_SCHEMA_ALLELE_MODE_SUCCESS = 'FETCH_SCHEMA_ALLELE_MODE_SUCCESS';
export const FETCH_SCHEMA_ALLELE_MODE_FAIL = 'FETCH_SCHEMA_ALLELE_MODE_FAIL';
export const FETCH_SCHEMA_BOX_START = 'FETCH_SCHEMA_BOX_START';
export const FETCH_SCHEMA_BOX_SUCCESS = 'FETCH_SCHEMA_BOX_SUCCESS';
export const FETCH_SCHEMA_BOX_FAIL = 'FETCH_SCHEMA_BOX_FAIL';

export const FETCH_ALLELE_CONTRIBUTIONS_START = 'FETCH_ALLELE_CONTRIBUTIONS_START';
export const FETCH_ALLELE_CONTRIBUTIONS_SUCCESS = 'FETCH_ALLELE_CONTRIBUTIONS_SUCCESS';
export const FETCH_ALLELE_CONTRIBUTIONS_FAIL = 'FETCH_ALLELE_CONTRIBUTIONS_FAIL';

export const FECTH_LOCUS_FASTA_START = 'FECTH_LOCUS_FASTA_START';
export const FECTH_LOCUS_FASTA_SUCCESS = 'FECTH_LOCUS_FASTA_SUCCESS';
export const FECTH_LOCUS_FASTA_FAIL = 'FECTH_LOCUS_FASTA_FAIL';
export const FECTH_LOCUS_UNIPROT_START = 'FECTH_LOCUS_UNIPROT_START';
export const FECTH_LOCUS_UNIPROT_SUCCESS = 'FECTH_LOCUS_UNIPROT_SUCCESS';
export const FECTH_LOCUS_UNIPROT_FAIL = 'FECTH_LOCUS_UNIPROT_FAIL';

export const FECTH_ANNOTATIONS_START = 'FECTH_ANNOTATIONS_START';
export const FECTH_ANNOTATIONS_SUCCESS = 'FECTH_ANNOTATIONS_SUCCESS';
export const FECTH_ANNOTATIONS_FAIL = 'FECTH_ANNOTATIONS_FAIL';

export const FECTH_DESCRIPTIONS_START = 'FECTH_DESCRIPTIONS_START';
export const FECTH_DESCRIPTIONS_SUCCESS = 'FECTH_DESCRIPTIONS_SUCCESS';
export const FECTH_DESCRIPTIONS_FAIL = 'FECTH_DESCRIPTIONS_FAIL';

export const FECTH_SEQUENCES_START = 'FECTH_SEQUENCES_START';
export const FECTH_SEQUENCES_SUCCESS = 'FECTH_SEQUENCES_SUCCESS';
export const FECTH_SEQUENCES_FAIL = 'FECTH_SEQUENCES_SUCCESS';
