import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  // userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authCuserStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    // userId: action.userId,
    error: null,
    loading: false,
  });
};

const authCuserSuccess = (state, action) => {
  return updateObject(state, {
    cuser: action.cuser,
    error: null,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authCuserFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  // return updateObject(state, { token: null, userId: null });
  return updateObject(state, { token: null });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_CUSER_START:
      return authCuserStart(state, action);
    // case actionTypes.AUTH_CUSER_PROFILE_START:
    //   return authCuserProfileStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_CUSER_SUCCESS:
      return authCuserSuccess(state, action);
    // case actionTypes.AUTH_CUSER_PROFILE_SUCCESS:
    //   return authCuserProfileSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_CUSER_FAIL:
      return authCuserFail(state, action);
    // case actionTypes.AUTH_CUSER_PROFILE_FAIL:
    //   return authCuserProfileFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;
